import { createRouter, createWebHistory } from 'vue-router'
import auth from './middleware/auth'
import guest from './middleware/guest'
import middlewarePipeline from './middlewarePipeline'

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/Login.vue"),
    meta: {
      middleware: [guest],
    },
  },
//   {
//     path: "/register",
//     name: "register",
//     component: () => import("../views/pages/Register.vue"),
//     meta: {
//       middleware: [guest],
//     },
//   },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/pages/Home.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../views/pages/Account.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/results",
    name: "results",
    component: () => import("../views/pages/Results.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/records",
    name: "records",
    component: () => import("../views/pages/Records.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/",
    name: "daily_games",
    component: () => import("../views/pages/DailyGames.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/daily_games_bet/:id",
    name: "daily_games_bet",
    component: () => import("../views/pages/DailyGamesBet.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/fatafat_games",
    name: "fatafat_games",
    component: () => import("../views/pages/FatafatGames.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/fatafat_game/:id",
    name: "fatafat_game",
    component: () => import("../views/pages/FatafatGame.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/fatafat_game_bet/:id",
    name: "fatafat_game_bet",
    component: () => import("../views/pages/FatafatGamesBet.vue"),
    meta: {
      middleware: [auth],
    },
  },
  {
    path: "/change_password",
    name: "change_password",
    component: () => import("../views/pages/ChangePassword.vue"),
    meta: {
      middleware: [auth],
    },
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: function () {
  //     return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  //   }
  // }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware

    const context = {
        to,
        from,
        next
    }


    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })

})

export default router
