import { createApp } from 'vue'
import App from './App.vue'
import axios from './axios';
import {io} from "socket.io-client"
import VueSweetalert2 from "vue-sweetalert2";

import './registerServiceWorker'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
const app = createApp(App)
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$alert = VueSweetalert2.alert;
app.config.globalProperties.$fire = VueSweetalert2.fire;
app.config.globalProperties.$socketIo = io(process.env.VUE_APP_SOCKET_API, {
    transports: ["websocket"],
    upgrade: false,
});
app.use(router)

app.mount('#app')
